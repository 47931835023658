import React, { useState } from "react"
import { Alert, Input, message } from "antd"
import AliceCarousel from "react-alice-carousel"
import { subDays, eachDayOfInterval, format as dateFormat } from "date-fns"
import ChartLine from "@/components/ChartLine"
import * as style from "./style.module.scss"
import { Link } from "gatsby"
import tokens from "@/tokens"

const Home = () => {
  const [email, setEmail] = useState()
  const [loading, setLoading] = useState(false)

  const subscribe = async () => {
    const validEmail = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )

    if (validEmail) {
      let formData = new FormData()
      formData.append("EMAIL", email)
      setLoading(true)
      await fetch(
        "https://raynetwork.us20.list-manage.com/subscribe/post?u=630230660ec54c50b0c34762c&id=844a6bbde0",
        {
          method: "post",
          mode: "no-cors",
          body: formData,
        }
      )
      setLoading(false)
      setEmail()
      message.success("You have successfully subscribed!")
    } else {
      message.error("Please enter a valid email address.")
    }
  }

  return (
    <div className="overflow-hidden">
      <div className="ray__block pt-5">
        <div className={style.promo}>
          <img src="/resources/partials/4.png" alt="" className={style.img} />
          <div className={style.content}>
            <h1 className="max-width-800 lh-1 mb-4">
              <strong className="bolder">RaySwap Protocol</strong>
            </h1>
            <p className="max-width-900 font-size-18 mb-3">RaySwap is an automated liquidity protocol for creating liquidity and trading native tokens on Cardano. It eliminates trusted intermediaries and unnecessary forms of rent extraction, allowing for fast, efficient trading.</p>
            <p className="max-width-900 font-size-18 mb-5">Swap, earn, and build on the leading decentralized crypto trading protocol.</p>
            <div className="mb-5">
              <Link to="/swap/" className="ant-btn ray__btn ray__btn--large ray__btn--dark ray__btn--round me-3"><i className="ri ri-repeat me-2" /> Swap</Link>
              <Link to="/pools/" className="ant-btn ray__btn ray__btn--large ray__btn--dark ray__btn--round me-3"><i className="ri ri-activity me-2" /> Pools</Link>
            </div>
            <div className="mb-5">
              <h6><strong>Daily Gainers & Losers</strong></h6>
              <div className={style.slider}>
                <div className={style.sliderInner}>
                  <AliceCarousel
                    autoHeight={false}
                    infinite
                    disableButtonsControls
                    disableDotsControls
                    autoPlay
                    autoPlayInterval={3000}
                    mouseTracking
                    autoWidth
                  >
                    {[...tokens].sort((a, b) => b.change - a.change).map((item, index) => {
                      const labels = eachDayOfInterval({ start: subDays(new Date(), 6), end: new Date() }).map((d) => dateFormat(d, 'PP'))
                      const dataset = item.data
                      return (
                        <div key={index} className={style.item}>
                          <div className={style.itemInner}>
                            <div className={style.itemHead}>
                              <div className={style.itemImage}>
                                <img src={`/resources/tokens/${item.img}`} alt={item.ticker} />
                              </div>
                              <div className={style.itemChart} style={{ height: 37 }}>
                                <ChartLine dataset={dataset} labels={labels} height={37} />
                              </div>
                            </div>
                            <div className={style.itemTitle}>{item.ticker} - {item.project}</div>
                            <div className={style.itemPrice}>{item.price.toFixed(6)} ADA</div>
                            {item.change >= 0 && <div className={`${style.itemStyle} ${style.itemStyleGainer}`}>{(item.change * 100).toFixed(2)}%</div>}
                            {item.change < 0 && <div className={`${style.itemStyle} ${style.itemStyleLooser}`}>{(item.change * -100).toFixed(2)}%</div>}
                          </div>
                        </div>
                      )
                    })}
                  </AliceCarousel>
                </div>
              </div>
            </div>
            <h6><strong>Subscribe to get early access</strong></h6>
            <div className="max-width-400 mb-5">
              <Input.Search
                placeholder="Enter your email"
                allowClear
                enterButton="Subscribe"
                size="large"
                value={email}
                type="email"
                onSearch={() => subscribe()}
                onChange={(e) => setEmail(e.target.value)}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
