// extracted by mini-css-extract-plugin
export var content = "style-module--content--QBsdZ";
export var img = "style-module--img--mOSvV";
export var item = "style-module--item--pE8z8";
export var itemChart = "style-module--itemChart--9m3pl";
export var itemHead = "style-module--itemHead--4fgBL";
export var itemImage = "style-module--itemImage--FIZ06";
export var itemInner = "style-module--itemInner--d6f-j";
export var itemPrice = "style-module--itemPrice--L0L5J";
export var itemStyle = "style-module--itemStyle--7Tix5";
export var itemStyleGainer = "style-module--itemStyleGainer--DRq9G";
export var itemStyleLooser = "style-module--itemStyleLooser--lzDSN";
export var itemTitle = "style-module--itemTitle--R6Cqk";
export var promo = "style-module--promo--jS2qA";
export var slider = "style-module--slider--MZkOp";
export var sliderInner = "style-module--sliderInner--WH+KZ";